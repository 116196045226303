.block.event {
    padding: 185px 15px 100px;

    .holder {
        margin: 0 auto;
        max-width: 414px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .header {
        color: #334251;
        font-family: 'Canela-Thin';
        text-align: center;
    }

    .title {
        font-size: 46px;
        line-height: 55px;
        text-transform: uppercase;
    }

    .desc {
        font-size: 28px;
        line-height: 32px;
        margin-top: 60px;
    }

    .item {
        display: block;
        margin-top: 80px;
        max-width: 100%;
        opacity: 1 !important;
        text-decoration: none;
        width: 100%;

        img {
            border-radius: 0;
            overflow: hidden;
            transition: all 250ms ease-in-out;
        }
    }

    .image {
        overflow: hidden;
    }

    .text-wrapper {
        margin-top: 40px;
        position: relative;
    }

    .item-title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 34px;
        padding-right: 50px;
    }

    .year {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 17px;
    }

    .item-desc {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 21px;
        margin-top: 30px;
    }

    .see-more {
        color: #92A1B1;
        font-family: 'Whyte-Light';
        font-size: 12px;
        line-height: 18px;
        margin-top: 40px;
        padding-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        width: fit-content;

        &:after {
            background: #979797;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .gplk-link {
        color: #707070;
        display: block;
        font-family: 'Canela-Thin';
        font-size: 28px;
        margin: 0 auto;
        margin-top: 100px;
        padding-bottom: 11px;
        position: relative;
        text-decoration: none;
        width: fit-content;

        &:after {
            background: #979797;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
}

@media (min-width: 768px) {

    .block.event {
        padding: 247px 0 150px;

        .holder {
            max-width: 1108px;
        }

        .title {
            font-size: 60px;
            line-height: 80px;
        }

        .desc {
            font-size: 30px;
            line-height: 36px;
            margin: 0 auto;
            margin-top: 56px;
            max-width: 382px;
            width: 100%;
        }

        .grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .item {
            margin-top: 150px;
            max-width: 48.6%;

            img {
                border-radius: 0;
            }

            .see-more {

                &:after {
                    transition: 550ms ease-in-out;
                    width: 0;
                }
            }

            &:hover {
    
                img {
                    border-radius: 262px 262px 0 0;
                }

                .see-more {
                    color: #68696A;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        .text-wrapper {
            margin-top: 45px;
        }

        .item-title {
            font-size: 30px;
            line-height: 36px;
        }

        .year {
            font-size: 16px;
            top: 8px;
        }

        .item-desc {
            font-size: 16px;
            line-height: 23px;
            margin-top: 28px;
        }

        .see-more {
            margin-top: 45px;
            padding-bottom: 10px;
        }

        .gplk-link {
            font-size: 40px;
            margin-top: 144px;
            padding-bottom: 13px;
        }
    }
}