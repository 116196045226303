.footer {
    background: #DFE9F0;
    padding: 100px 30px 40px;
    position: relative;
    z-index: 1001;

    .title {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 28px;
        max-width: 354px;
        width: 100%;
    }

    .content {
        padding-top: 106px;
    }

    .flex-content {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }

    .flex-wrapper {
        position: relative;
    }

    .logo {
        background: url('../../imgs/casablanca-azul.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 33px;
        width: 51px;
    }

    a, span {
        display: block;
        text-decoration: none;
    }

    .link, .copyright, .catalogue {
        color: #171C26;
        font-family: 'Whyte-Light';
        font-size: 11px;
    }

    .copyright {
        margin-top: 25px;
    }

    .link {
        margin-top: 10px;
    }

    .catalogue {
        margin-top: 49px;

        &.desktop {
            display: none;
        }

        &.mobile {
            display: block;
        }
    }

    .list {
        display: flex;
        justify-content: flex-end;
    }

    li {

        &.opt-desktop {
            display: none;
        }

        .opt {
            margin-right: 25px;
        }

        &:last-child {

            .opt {
                margin-right: unset;
            }
        }
    }

    .opt {
        color: #171C26;
        font-family: 'Whyte-Light';
        font-size: 12px;
        line-height: 14px;
    }
}

@media (min-width: 768px) {

    .footer {
        max-height: 100vh;
        overflow: hidden;
        padding: 0;

        .holder {
            margin: 0 auto;
            max-width: 1300px;
            width: 100%;
        }

        .container-fluid {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: space-around;
            padding: 120px 15px 60px;
        }

        .title {
            font-size: 35px;
            line-height: 48px;
            max-width: 555px;
        }

        .content {
            padding-top: 117px;
        }

        .logo {
            height: 43px;
            width: 67px;
                
            &:hover {
                background: url('../../imgs/casablanca-azul-2.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .link, .copyright {
            font-size: 12px;
            line-height: 14px;
        }

        .copyright {
            margin-top: 28px;
        }
    
        .link {
            margin-top: 5px;
        }

        .catalogue {
            bottom: 0;
            margin-top: unset;
            position: absolute;
            right: -190px;
    
            &.desktop {
                display: block;
            }
    
            &.mobile {
                display: none;
            }
        }

        li {

            &.opt-desktop {
                display: block;
            }

            .opt {
                margin-right: 30px;
            }
        }

        .sublist {
            padding-top: 3px;
        }

        .subopt {
            color: #171C26;
            font-family: 'Whyte-Light';
            font-size: 10px;
            line-height: 12px;
            margin-top: 12px;
        }
    }
}

@media (max-height: 800px) {

    .footer {

        .container-fluid {
            justify-content: center;
        }

        .title {
            font-size: 20px;
            line-height: 28px;
            max-width: 400px;
        }
    }
}