.block.thanks {
    height: 100vh;
    position: relative;

    .text {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 30px;
        left: 50%;
        max-width: 320px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .link-inicio{
        max-width: 184px;
        width: 100%;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #707070;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        border-radius: 20px;
        text-transform: uppercase;
        color: #202934;
        font-family: "Canela-Thin";
    }
}