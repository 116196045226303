.block.grid-foundations {
    padding: 120px 15px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 550px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .text {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 34px;
        margin: 0 auto;
        max-width: 670px;
        padding-bottom: 75px;
        text-align: center;
        width: 100%;
    }

    .item {
        display: block;
        opacity: 1 !important;
        padding-bottom: 80px;
        text-decoration: none;

        &:nth-last-child(1) {
            padding-bottom: unset;
        }
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 28px;
        padding-top: 40px;
    }

    .desc {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 21px;
        padding-top: 30px;
    }

    .see-more {
        color: #92A1B1;
        font-family: 'Whyte-Light';
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 5px;
        padding-top: 40px;
        position: relative;
        text-transform: uppercase;
        width: fit-content;

        &:after {
            bottom: 0;
            background: #92A1B1;
            display: block;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
}

@media (min-width: 768px) {

    .block.grid-foundations {
        padding: 152px 15px 200px;

        .holder {
            max-width: 1108px;
        }

        .text {
            font-size: 30px;
            padding-bottom: 184px;
        }

        .flex-content {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .item {
            cursor: initial;
            max-width: 48.7%;
            padding-bottom: 150px;
            width: 100%;

            &.link {
                cursor: pointer;
            }

            img {
                border-radius: 0;
                transition: all 250ms ease-in-out;
            }

            .see-more {

                &:after {
                    transition: all 250ms ease-in-out;
                    width: 0;
                }
            }

            &:hover {
    
                img {
                    border-radius: 262px 262px 0 0;
                }

                .see-more {
                    color: #202934;
    
                    &:after {
                        background: #202934;
                        width: 100%;
                    }
                }
            }
        }

        .title {
            font-size: 30px;
            padding-top: 45px;
        }

        .desc {
            font-size: 16px;
            line-height: 23px;
            max-width: 413px;
            padding-top: 27px;
            width: 100%;
        }

        .see-more {
            padding-bottom: 10px;
            padding-top: 45px;
        }
    }
}