.block.privacy {
    padding: 100px 25px;
    position: relative;

    .text {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 20px;
        line-height: 26px;
        width: 100%;
    }
}

@media (min-width: 768px) {

    .block.privacy {
        padding: 200px 50px;

        .text {
            font-size: 30px;
            line-height: 36px;
        }
    }
}