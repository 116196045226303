.loader {
    background: #EFEBE8;
    height: 100vh;
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: all 750ms ease-in-out;
    width: 100%;
    z-index: 1001;

    &:after {
        background: url('../../imgs/scroll-down.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 50px;
        content: '';
        display: block;
        height: 30px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 36px;
    }

    .content {
        left: 50%;
        max-width: calc(100% - 26px);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .logo {
        left: 50%;
        max-width: 185px;
        opacity: 0;
        position: absolute;
        top: 35px;
        transform: translate(-50%, 100%);
        transition: all 1000ms ease-in-out;
        width: 100%;

        &.inview {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    .background {
        opacity: 0;
        transform: translate(0, -10%);
        transition: all 550ms ease-in-out;

        &.inview {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    video {
        display: block;
        max-width: 100%;
        pointer-events: none;
        width: 100%;
    }

    .text {
        bottom: 17px;
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 11px;
        line-height: 13px;
        left: 50%;
        max-width: 93px;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);

        span {
            display: inline-block;
            opacity: 0;
            position: relative;
            transform: translateY(50%);
            transition: opacity 350ms ease-in-out, transform 550ms;

            &:after {
                color: transparent;
                content: '·';
                display: inline-block;
            }

            &.inview {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    &.to-hide {
        opacity: 0;
    }
}

@media (min-width: 768px) {

    .loader {

        .content {
            max-width: 59.5%;
        }

        .logo {
            max-width: 39%;
            top: 80px;
        }

        .text {
            bottom: 38px;
            font-size: 20px;
            line-height: 24px;
            max-width: 168px;
        }
    }
}