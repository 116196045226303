.block.gallery-allies {
    padding: 150px 22px 58px;

    .holder {
        margin: 0 auto;
        max-width: 414px;
        width: 100%;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 35px;
        text-align: center;
        line-height: 55px;
    }

    .text-wrapper {
        margin-bottom: 100px;
    }

    .text-one {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 30px;
        padding: 50px 0;
    }

    .text-two {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 20px;
    }

    .contact, .location {
        margin-top: 50px;
    }

    .name {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 30px;
        line-height: 36px;
    }

    .txt {
        color: #9F9F9F;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 22px;
        margin-top: 20px;
    }

    .flex-content {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 30px;
        padding-top: 40px;
    }

    .prev-next-content {
        margin-top: 100px;
    }

    .current {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 28px;
        order: 3;
        margin: 0 auto;
        margin-top: 100px;
        max-width: 100%;
        padding-bottom: 11px;
        position: relative;
        text-decoration: none;
        width: fit-content;

        &:after {
            background: #979797;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .prev, .next {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 26px;
        max-width: 50%;
        text-decoration: none;
        width: 100%;

        span {
            display: block;
        }

        span.prev-name, span.next-name {
            color: #92A1B1;
            font-family: 'Whyte-Light';
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .next {
        text-align: right;
    }

    .image {
        padding-bottom: 80px;
    }

    .images-wrapper-one, .images-wrapper-two, .images-wrapper-three, .images-wrapper-four {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 85.5%;
        padding-bottom: 80px;
        position: relative;
        width: 100%;
    }

    .image-one {
        max-width: 53.2%;
        padding-bottom: 154px;
        width: 100%;
    }

    .image-two {
        bottom: 80px;
        max-width: 65%;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .images-wrapper-two, .images-wrapper-four {
        display: block;
    }

    .image-three, .image-four {
        max-width: 72.7%;
        width: 100%;
    }

    .image-four {
        margin-left: auto;
        margin-top: 20px;
    }
}

@media (min-width: 768px) {

    .block.gallery-allies {
        padding: 250px 70px 58px;

        .holder {
            max-width: 1300px;
        }

        .title {
            font-size: 60px;
            line-height: 80px;
        }

        .text-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 200px;
            margin-top: 197px;
            max-width: 1093px;
            width: 100%;
        }

        .text-one {
            font-size: 20px;
            line-height: 26px;
            max-width: 453px;
            padding: unset;
            padding-right: 12px;
            width: 100%;
        }

        .text-two {
            max-width: 315px;
            padding-left: 15px;
            width: 100%;
        }

        .flex-content {
            flex-wrap: unset;
            padding: 0;
            padding-top: unset;
        }

        .prev {
            order: 1;
        } 

        .current {
            font-size: 30px;
            margin-top: unset;
            order: 2;
            padding-bottom: 14px;
        }

        .next {
            order: 3;
        }

        .prev, .next {
            font-size: 30px;

            span.prev-name, span.next-name {
                font-size: 14px;
                margin-top: 12px;
            }
        }

        .extra-text {
            display: flex;
            justify-content: space-between;
            max-width: 461px;
            width: 100%;
        }

        .contact, .location {
            margin-top: unset;
        }

        .contact {
            max-width: 150px;
            padding-right: 22px;
            width: 100%;
        }

        .location {
            max-width: 235px;
            padding-left: 15px;
            width: 100%;
        }

        .txt {
            margin-top: 39px;
        }

        .image {
            padding-bottom: 150px;
        }

        .images-wrapper-two {
            margin: unset;
            max-width: 100%;
            padding-bottom: 150px;
        }

        .images-wrapper-one {
            max-width: 82.9%;
        }

        .image-one {
            max-width: 48.6%;
            padding-bottom: 425px;
        }

        .image-two {
            bottom: 150px;
            max-width: 59%;
        }

        .images-wrapper-two {
            display: flex;
        }

        .image-three, .image-four {
            max-width: 48.8%;
        }

        .image-four {
            margin-left: unset;
            margin-top: unset;
        }

        .prev-next-content {
            margin-top: 200px;
        }
    }
}