.block.banner {
    padding: 60px 15px 80px;

    .holder {
        margin:  auto;
        max-width: 414px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .content {
        padding: 0 13px;
        position: relative;
    }

    .background {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 100%;
        left: 0;
        position: absolute !important;
        top: 0;
        width: 100%;

        &:after {
            background: #000000;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.4;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .text-wrapper {
        position: relative;
    }

    .text {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        em {
            display: block;
            font-family: 'Whyte-Light';
            font-size: 16px;
            font-style: normal;
            line-height: 21px;
            margin-top: 20px;
        }
    }
}

@media (min-width: 768px) {

    .block.banner {
        padding: 130px 15px 238px;

        .holder {
            max-width: 1330px;
        }

        .content {
            padding: 0 15px;
        }

        .text-wrapper {
            margin: 0 auto;
            max-width: 634px;
            width: 100%;
        }

        .text {
            font-size: 32px;
            line-height: 38px;

            em {
                font-size: 16px;
                line-height: 21px;
                margin-top: 27px;
            }
        }
    }
}