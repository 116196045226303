.block.cover-allies {
    height: 590px;
    position: relative;
    width: 100vw;

    .background {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 100%;
        left: 0;
        position: absolute !important;
        top: 0;
        width: 100%;

        &:after {
            background: #000000;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.54;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .text-wrapper {
        color: #FBF8F8;
        font-family: Canela-Thin;
        font-size: 28px;
        letter-spacing: 0;
        left: 50%;
        line-height: 36px;
        max-width: 414px;
        padding: 0 22px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

@media (min-width: 768px) {

    .block.cover-allies {
        height: 735px;

        .text-wrapper {
            font-size: 40px;
            line-height: 48px;
            max-width: 900px;
        }
    }
}