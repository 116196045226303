.block.allies-grid {
    padding: 162px 22px 114px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 414px;
        width: 100%;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 40px;
        letter-spacing: 0;
        line-height: 60px;
        text-align: center;
        text-transform: uppercase;
    }

    .text {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 30px;
        letter-spacing: 0;
        margin: 0 auto;
        margin-top: 42px;
        max-width: 630px;
        text-align: center;
        width: 100%;
    }

    .item {
        display: block;
        margin-top: 80px;
        max-width: 100%;
        opacity: 1 !important;
        text-decoration: none;
        width: 100%;

        img {
            border-radius: 0;
            overflow: hidden;
            transition: all 250ms ease-in-out;
        }
    }

    .image {
        overflow: hidden;
    }

    .text-wrapper {
        margin-top: 40px;
        position: relative;
    }

    .item-title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 34px;
        padding-right: 50px;
    }
    
    .item-desc {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 21px;
        margin-top: 30px;
    }

    .see-more {
        color: #92A1B1;
        font-family: 'Whyte-Light';
        font-size: 12px;
        line-height: 18px;
        margin-top: 40px;
        padding-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        width: fit-content;

        &:after {
            background: #92A1B1;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
}

@media (min-width: 768px) {

    .block.allies-grid {
        padding: 162px 22px 114px;

        .holder {
            max-width: 1108px;
        }

        .title {
            font-size: 60px;
            line-height: 80px;
        }

        .text {
            font-size: 30px;
        }

        .grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .item {
            margin-top: 150px;
            max-width: 48.6%;

            img {
                border-radius: 0;
            }

            .see-more {
                transition: all 550ms ease-in-out;

                &:after {
                    transition: all 550ms ease-in-out;
                    width: 0;
                }
            }

            &:hover {
    
                img {
                    border-radius: 262px 262px 0 0;
                }

                .see-more {
                    color: #202934;

                    &:after {
                        background: #202934;
                        width: 100%;
                    }
                }
            }
        }

        .text-wrapper {
            margin-top: 45px;
        }

        .item-title {
            font-size: 30px;
            line-height: 36px;
        }

        .item-desc {
            font-size: 16px;
            line-height: 23px;
            margin-top: 28px;
        }

        .see-more {
            margin-top: 45px;
            padding-bottom: 10px;
        }
    }
}