.block.first-section-foundation {
    position: relative;

    .background {
        display: none;
    }

    .text-wrapper {
        background: #92A1B1;
        color: #FBF8F8;
        padding: 80px 30px;
    }

    .text-one {
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 34px;
    }

    .text-two {
        font-family: 'Whyte-Light';
        font-size: 16px;
        line-height: 23px;
        padding-top: 60px;
    }
}

@media (min-width: 768px) {

    .block.first-section-foundation {

        .flex-content {
            align-items: stretch;
            display: flex;
        }

        .image-wrapper, .text-wrapper {
            max-width: 50%;
            position: relative;
            width: 100%;
        }

        .image {
            display: none;
        }

        .background {
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            display: block;
            height: 100%;
            width: 100%;
        }

        .text-wrapper {
            padding: 198px 15px 223px;
        }

        .text-one, .text-two {
            margin: 0 auto;
            max-width: 523px;
            width: 100%;
        }

        .text-one {
            font-size: 32px;
            line-height: 38px;
        }

        .text-two {
            padding-top: 93px;

            p {
                margin: unset;
                max-width: 318px;
                width: 100%;
            }
        }
    }
}