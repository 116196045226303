.block.testimonials {
    font-family: 'Canela-Thin';
    padding: 120px 30px 100px;
    position: relative;

    .title {
        color: #202934;
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto;
        max-width: 280px;
        text-align: center;
        width: 100%;
    }

    .text {
        color: #334251;
        font-size: 22px;
        line-height: 28px;
        padding-top: 100px;
        text-align: center;
    }

    strong {
        color: #92A1B1;
        font-weight: normal;
    }

    a {
        background: transparent;
        border: 1px solid #171C26;
        border-radius: 50px;
        color: #171C26;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 10px;
        margin: 0 auto;
        margin-top: 30px;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;
    }

    .btn-effect {

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }
    }
    .carrousel {
        margin-top: 80px;
        padding: 0 31px;
        position: relative;
        &.desk{
            display: none;
        }
        &.mobile{
            display: block;
        }
    }

    .slick-track {
        align-items: center;
        display: flex;
    }

    .image {

        img {
            border-radius: 262px 262px 0 0;
            overflow: hidden;
        }
    }

    .testimonial-text {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 17px;
        padding: 60px 0 30px;
        text-align: center;
    }

    .author {
        color: #202934;
        font-size: 22px;
        margin-top: 30px;
    }

    .numbers-wrapper {
        bottom: -2%;
        color: #707070;
        display: flex;
        font-family: 'Whyte-Light';
        font-size: 14px;
        justify-content: center;
        margin: 0 auto;
        margin-top: 60px;
        position: relative;
        width: 72px;
        z-index: 222;
    }

    .arrows-container {
        height: 17px;
        margin: 0 auto;
        margin-top: 60px;
        position: relative;
        width: 72px;
    }

    .slick-prev, .slick-next {
        opacity: 0;
    }

    .slick-prev-btn, .slick-next-btn {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        bottom: 0;
        color: transparent;
        height: 14px;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        z-index: 11;

        &:before {
            content: none;
        }
    }

    .slick-prev-btn {
        background: url('../../imgs/arrow-izq.svg');
        left: 0;
    }

    .slick-next-btn {
        background: url('../../imgs/arrow-der.svg');
        right: 0;
    }
}

@media (min-width: 768px) {

    .block.testimonials {
        padding: 170px 15px 150px;

        .header {
            margin: 0 auto;
            max-width: 390px;
            width: 100%;
        }

        .title {
            font-size: 35px;
            line-height: 48px;
            max-width: 100%;
        }

        .text {
            font-size: 30px;
            line-height: 36px;
            margin: 0 auto;
            max-width: 633px;
            padding-top: 150px;
            width: 100%;
        }

        a {
            font-size: 12px;
            margin-top: 106px;
            overflow: hidden;
            position: relative;

            .btn-effect {

                &:nth-child(1) {
                    transform: translateY(0);
                }

                &:nth-child(2) {
                    transform: translate(-50%, 170%);
                }
            }

            &:hover {

                .btn-effect {

                    &:nth-child(1) {
                        transform: translateY(-170%);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .btn-effect {
            transition: cubic-bezier(0.23, 1, 0.32, 1) 600ms transform;;
    
            &:nth-child(1) {
                display: block;
            }
    
            &:nth-child(2) {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }

        .content {
            padding-top: 100px;
        }

        .carrousel-container {
            margin: 0 auto;
            max-width: 1045px;
            position: relative;
            width: 100%;
        }

        .carrousel {
            padding: 0;
            &.desk{
                display: block;
            }
            &.mobile{
                display: none;
            }
        }

        .item {
            align-items: flex-start;
            display: inline-flex !important;
            justify-content: space-between;
            background: #FAF7F2;
        }

        .image-wrapper {
            border-radius: 262px 262px 0 0;
            max-width: 50%;
            overflow: hidden;
            width: 100%;
        }

        .text-wrapper {
            max-width: 36.3%;
            padding-bottom: 74px;
            width: 100%;
        }

        .testimonial-text {
            font-size: 18px;
            line-height: 22px;
            padding: 0 0 50px;
        }

        .author {
            font-size: 26px;
            margin-bottom: 30px;
            margin-top: 0;
        }

        .numbers-wrapper {
            font-size: 20px;
            margin: 0;
            margin-top: 54px;
            width: 111px;
        }
        .swiper-pagination-fraction{
            bottom: 55px;
            left: 3%;
            width: 4%;
            transform: translateY(-3px);
            span{
                font-family: "Whyte-Light";
                font-size: 18px;
                color: #707070;
            }
        }
        .swiper-button-prev, .swiper-button-next{
            top: 89.1%;
            &::after{
                font-size: 17px;
                color: #707070;
            }
        }
        .swiper-button-prev{
            left: 0%;
        }
        .swiper-button-next{
            right: 90%;
        }
        .arrows-container {
            bottom: 0;
            height: 24px;
            margin-top: unset;
            position: absolute;
            right: 29.5%;
            width: 111px;
        }

        .slick-prev-btn, .slick-next-btn {
            cursor: pointer;
            height: 20px;
            width: 9px;
        }
    }
}

@media (min-width: 900px) {

    .block.testimonials {

        .arrows-container {
            right: 22.5%;
        }
    }
}

body.dark-theme{
    .block.testimonials .item{
        background: #202934;
    }
}