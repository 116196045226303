.navigation {
    background: rgba(244, 240, 234, .9);
    left: 0;
    padding: 25px;
    position: fixed;
    top: 0;
    transition: all 550ms;
    width: 100%;
    z-index: 999;

    .logo {
        background: url('../../imgs/casablanca-b.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 20px;
        margin: 0 auto;
        opacity: 1;
        position: relative;
        width: 141px;
        z-index: 1;
    }

    .list-wrapper {
        background: #EDE9E2;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        transform: translateX(100%);
        transition: all 750ms ease-in-out;
        width: 100%;
        z-index: 1;

        &:after {
            background: url('../../imgs/casablanca-b.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 21px;
            left: 50%;
            position: absolute;
            top: 25px;
            transform: translateX(-50%);
            width: 141px;
        }
    }

    .flex-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 50px;
    }

    .theme-btn {
        background: url('../../imgs/casablanca-icon-b.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 40px;
        height: 30px;
        left: 50px;
        position: fixed;
        width: 48px;
    }
    .theme-btn-b {
        background: url('../../imgs/casablanca-icon-b.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 40px;
        height: 30px;
        left: 50px;
        position: fixed;
        width: 48px;
    }

    .left-side {
        padding-bottom: 70px;
    }

    .opt {
        padding-bottom: 70px;

        &:last-child {
            padding-bottom: unset;
        }
    }

    .link {
        color: #171C26;
        display: block;
        font-family: 'Canela-Thin';
        font-size: 24px;
        line-height: 18px;
        text-decoration: none;
        text-transform: uppercase;
    }

    .no-pointer-events {
        display: inline-block;
        pointer-events: none;
        transition: all 550ms ease-in-out;
    }

    .invisible {
        opacity: 0 !important;
    }

    .sublist {
        display: flex;
        flex-wrap: wrap;
    }

    .sub-opt {
        display: block;
        max-width: 50%;
        padding-top: 30px;
        width: 100%;
    }

    .sub-link {
        color: #707070;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 9px;
        text-decoration: none;
    }

    .toggle-btn {
        background: transparent;
        border: none;
        display: block;
        height: 15px;
        outline: none;
        padding: 0;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        z-index: 2;
    }

    .bar {
        background: #171C26;
        height: 1px;
        left: 0;
        position: absolute;
        transition: all 550ms ease-in-out;
        width: 100%;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            opacity: 1;
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 0;
        }
    }

    &.menu-inview {

        .logo {
            background: url('../../imgs/casablanca-b.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &.expanded {

        .list-wrapper {
            transform: translateX(0);
        }

        .bar {
            background: #171C26 !important;

            &:nth-child(1) {
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
            }

            

            &:nth-child(2) {
                bottom: 50%;
                transform: rotate(-45deg) translateY(50%);
            }
        }
    }

    &.back-transparent {
        background: transparent;

        .logo {
            background: url('../../imgs/casablanca-w.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .bar {
            background: #EDE9E2;
        }
    }

    &.scrollTop {
        background: rgba(244, 240, 234, .9);
        padding: 15px 25px;

        .theme-btn {
            background: url('../../imgs/casablanca-azul.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .logo {
            background: url('../../imgs/casablanca-b.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .bar {
            background: #171C26;
        }
    }

    &.menu-inview {
        z-index: 1002;
    }
}

@media (max-height: 750px) {

    .navigation {

        .left-side {
            padding-bottom: 35px;
        }

        .opt {
            padding-bottom: 35px;
        }

        .sub-opt {
            padding-top: 20px;
        }
    }
}

@media (min-width: 768px) {

    .navigation {
        padding: 40px 15px;

        &.color-black{
            .opt{
                .sublist{
                    .sub-opt{
                        span{
                            color: #707070;
                        }
                    }
                }
            }
        }

        .logo {
            height: 37px;
            width: 170px;
            z-index: 3;
        }

        .list-wrapper {
            background: transparent;
            height: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &:after {
                content: none;
            }
        }

        .flex-content {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 15px;
        }

        .left-side {
            align-items: center;
            display: flex;
            padding-bottom: unset;
        }

        .theme-btn {
            background: url('../../imgs/casablanca-azul.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: unset;
            cursor: pointer;
            left: unset;
            position: relative;

            &:hover {
                background: url('../../imgs/casablanca-gris-2.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .theme-btn-b {
            background: url('../../imgs/casablanca-azul.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: unset;
            cursor: pointer;
            left: unset;
            position: relative;

            &:hover {
                background: url('../../imgs/casablanca-azul-2.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        .list {
            display: flex;
        }

        .opt {
            margin-left: 20px;
            padding-bottom: unset;
            position: relative;
        }

        .link {
            color: #000000;
            font-family: 'Whyte-Light';
            font-size: 10px;
            line-height: 18px;
            position: relative;
            z-index: 2;

            &:after {
                background: #979797;
                bottom: -9px;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                transition: all 550ms ease-in-out;
                width: 0;
            }

            &:hover {

                &:after {
                    width: 100%;
                }
            }
        }

        .pathActive{
            &:after{
                width: 100%;
            }
        }
        .sublist {
            left: 0;
            padding-top: 14px;
            position: absolute;
            top: 100%;
        }

        .sub-link {
            color: #ffffff;
            font-size: 11px;
            position: relative;

            &:after {
                background: #979797;
                bottom: -4px;
                content: '';
                display: block;
                height: 1px;
                left: 0;
                position: absolute;
                transition: all 550ms ease-in-out;
                width: 0;
            }

            &:hover {

                &:after {
                    width: 30%;
                }
            }
        }

        .has-sublist {

            .sublist {
                opacity: 0;
                pointer-events: none;
                transition: opacity 550ms ease-in-out, transform 550ms ease-in-out;
                transform: translateY(-50%);
                z-index: 1;
            }

            &:hover {

                .sublist {
                    height: auto;
                    opacity: 1;
                    pointer-events: initial;
                    transform: translateY(0);
                }
            }
        }

        .sub-opt {
            max-width: 100%;
            padding-top: 14px;
        }

        .toggle-btn {
            display: none;
        }

        &.back-transparent {

            .theme-btn {
                background: url('../../imgs/casablanca-gris.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .link {
                color: #fff;
            }

            .sub-link {
                color: #fff;
            }
        }

        &.scrollTop {
            padding: 20px 15px;

            .theme-btn {
                background: url('../../imgs/casablanca-azul.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        
            .theme-btn {
                
                &:hover {
                    background: url('../../imgs/casablanca-azul-2.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            .link {
                color: #000000;
            }

            .sub-link {
                color: #000000;
            }
        }
    }
}

@media (min-width: 1000px) {

    .navigation {

        .flex-content {
            padding: 0 46px;
        }

        .opt {
            margin-left: 40px;
        }

        .link {
            font-size: 12px;
        }
    }
}