.block.cover-foundation-template {
    padding: 185px 15px 191px;

    .container-fluid {
        padding: 0 15px;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 46px;
        line-height: 55px;
        text-align: center;
    }
}

@media (min-width: 768px) {

    .block.cover-foundation-template {
        padding: 253px 15px 240px;

        .holder {
            margin: 0 auto;
            max-width: 475px;
            width: 100%;
        }

        .title {
            font-size: 60px;
            line-height: 70px;
        }
    }
}