.block.gallery-template {
    margin-bottom: 100px;
    padding-top: 185px;

    .holder {
        margin: 0 auto;
        max-width: 1330px;
        width: 100%;
    }

    .header {
        padding: 0 15px;
        text-align: center;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 46px;
        line-height: 55px;
        padding-bottom: 20px;
    }

    .year, .location {
        color: #334251;
        font-family: 'Whyte-Light';
        font-size: 12px;
        padding-top: 5px;
    }

    #iframe-content {
        margin-top: 50px;
    }

    iframe {
        margin: 0 auto;
        max-width: 560px;
        width: 100%;
    }

    .content {
        padding-top: 80px;
    }

    .images-wrapper-one, .images-wrapper-two, .images-wrapper-three, .images-wrapper-four {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 85.5%;
        padding-bottom: 80px;
        position: relative;
        width: 100%;
    }

    .image-one {
        max-width: 53.2%;
        padding-bottom: 154px;
        width: 100%;
    }

    .image-two {
        bottom: 80px;
        max-width: 65%;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .images-wrapper-two, .images-wrapper-four {
        display: block;
    }

    .image-three, .image-four, .image-six, .image-eight {
        max-width: 72.7%;
        width: 100%;
    }

    .image-four, .image-nine {
        margin-left: auto;
        margin-top: 20px;
    }

    .image-five {
        padding-bottom: 80px;
    }

    .image-six {
        padding-bottom: 134px;
    }

    .image-seven {
        bottom: 80px;
        max-width: 85%;
        position: absolute;
        right: 0;
    }

    .image-nine {
        max-width: 60%;
    }

    .flex-content {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 30px;
        padding-top: 40px;
    }

    .current {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 28px;
        order: 3;
        margin: 0 auto;
        margin-top: 100px;
        max-width: 100%;
        padding-bottom: 11px;
        position: relative;
        text-decoration: none;
        width: fit-content;

        &:after {
            background: #979797;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .prev, .next {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 26px;
        max-width: 50%;
        text-decoration: none;
        width: 100%;

        span {
            display: block;
        }

        span.prev-name, span.next-name {
            color: #92A1B1;
            font-family: 'Whyte-Light';
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .next {
        text-align: right;
    }
    
}

@media (min-width: 768px) {

    .block.gallery-template {
        padding-bottom: 149px;
        padding-top: 247px;

        .container-fluid {
            padding: 0 15px;
        }

        .header {
            padding: 0;
        }

        .title {
            font-size: 85px;
            line-height: 80px;
            padding-bottom: 74px;
        }

        .year, .location {
            font-size: 12px;
            line-height: 20px;
            margin: 0 auto;
            max-width: 82.9%;
            padding-top: 1px;
            text-align: left;
            width: 100%;
        }

        .content {
            padding-top: 135px;
        }

        .images-wrapper-two, .images-wrapper-three, .images-wrapper-four {
            margin: unset;
            max-width: 100%;
            padding-bottom: 150px;
        }

        .images-wrapper-one {
            max-width: 82.9%;
        }

        .image-one {
            max-width: 48.6%;
            padding-bottom: 425px;
        }

        .image-two {
            bottom: 150px;
            max-width: 59%;
        }

        .images-wrapper-two, .images-wrapper-four {
            display: flex;
        }

        .image-three, .image-four, .image-six, .image-eight {
            max-width: 48.8%;
        }

        .image-four, .image-nine {
            margin-left: unset;
            margin-top: unset;
        }

        .image-five {
            padding-bottom: 150px;
        }

        .image-six {
            padding-bottom: unset;
        }

        .image-seven {
            bottom: unset;
            max-width: 57.3%;
            top: 25%;
            transform: translateY(-50%);
        }

        .image-nine {
            max-width: 48.8%;
            padding-right: 8.5%;
        }

        .flex-content {
            flex-wrap: unset;
            padding: 0;
            padding-top: unset;
        }

        .prev {
            order: 1;
        } 

        .current {
            font-size: 30px;
            margin-top: unset;
            order: 2;
            padding-bottom: 14px;
        }

        .next {
            order: 3;
        }

        .prev, .next {
            font-size: 30px;

            span.prev-name, span.next-name {
                font-size: 14px;
                margin-top: 12px;
            }
        }
    }
}