.block.foundations {
    padding: 60px 15px;

    .holder {
        margin: 0 auto;
        max-width: 414px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 30px;
        line-height: 53px;
        padding-bottom: 40px;
        text-align: center;
    }

    .text {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .see-more {
        border: 1px solid #707070;
        border-radius: 50px ;
        color: #171C26;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 10px;
        margin: 0 auto;
        margin-top: 30px;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;
    }

    .image-wrapper {
        padding: 30px 37px 35px;

        &.hidden-xs {
            display: none;
        }
    }

    .image {
        position: relative;
    }

    .background {
        height: 100%;
        left: 0;
        position: absolute !important;
        top: 0;
        width: 100%;
    }

    img {
        opacity: 0 !important;
    }

    .btn-effect {

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }
    }
}

@media (min-width: 768px) {

    .block.foundations {
        padding: 70px 15px;

        .holder {
            max-width: 1300px;
        }

        .container-fluid {
            padding: 0;
        }

        .flex-content {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .text-wrapper {
            max-width: 47.3%;
            order: 2;
            padding: 0 46px;
            width: 100%;
        }

        .title {
            font-size: 35px;
            line-height: 58px;
            padding-bottom: 57px;
        }

        .text {
            font-size: 30px;
            line-height: 36px;
        }

        .see-more {
            font-size: 12px;
            margin-top: 79px;
        }

        a {
            font-size: 12px;
            overflow: hidden;
            position: relative;

            .btn-effect {

                &:nth-child(1) {
                    transform: translateY(0);
                }

                &:nth-child(2) {
                    transform: translate(-50%, 170%);
                }
            }

            &:hover {

                .btn-effect {

                    &:nth-child(1) {
                        transform: translateY(-170%);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .btn-effect {
            transition: cubic-bezier(0.23, 1, 0.32, 1) 600ms transform;;
    
            &:nth-child(1) {
                display: block;
            }
    
            &:nth-child(2) {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }

        .image-wrapper {
            max-width: 43.9%;
            order: 1;
            padding: 0;
            width: 100%;

            &.hidden-xs {
                display: block;
            }

            &.visible-xs {
                display: none;
            }
        }
    }
}