.block.gallery-home {
    background: #EDE9E2;
    padding: 125px 0 120px;
 
    .header {
        padding: 0 30px;
    }

    .title {
        color: #334251;
        font-family: 'Canela-Thin';
        font-size: 40px;
        text-align: center;
        text-transform: uppercase;
    }

    .desc {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto;
        margin-top: 30px;
        max-width: 310px;
        text-align: center;
        width: 100%;
    }

    .content {
        margin-top: 100px;
    }

    .slick-slide {
        padding: 0 15px;
    }

    .slick-prev, .slick-next {
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
    }

    .slick-next{
        top: -50px !important;

        &:before{
            background-image: url('../../imgs/r-arrow-lightmode.svg');
            background-size: 14px 20px;
            content: "";
            display: block;
            width: 14px;
            height: 20px;
        }
    }

    .slick-prev{
        top: -50px !important;

        &:before{
            background-image: url('../../imgs/l-arrow-lightmode.svg');
            background-size: 14px 20px;
            content: "";
            display: block;
            width: 14px;
            height: 20px;
        }
    }
}

.dark-theme{
    .block.gallery-home{
        .slick-next{

            &:before{
                background-image: url('../../imgs/r-arrow-darkmode.svg');
            }
        }
    
        .slick-prev{
    
            &:before{
                background-image: url('../../imgs/l-arrow-darkmode.svg');
            }
        }
    }
}

@media (min-width: 768px) {

    .block.gallery-home {
        padding: 185px 0 227px;

        .header {
            margin: 0 auto;
            max-width: 450px;
            width: 100%;
        }

        .title {
            font-size: 60px;
            line-height: 80px;
        }

        .desc {
            font-size: 30px;
            line-height: 36px;
            margin-top: 41px;
            max-width: 100%;
        }

        .content {
            margin: 0 auto;
            margin-top: 166px;
            max-width: 70%;
            padding: 0 15px;
            width: 100%;
        }

        .carrousel {
            position: relative;
            .slick-active{
                .prueba{
                    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
                }
            }
        }

        .slick-track {
            align-items: center;
            display: inline-flex !important;
        }

        .slick-slide {
                
            img {
                margin-left: auto;
                max-width: 100%;
                transition: all 550ms ease-in-out;
                width: 100%;
            }
        }

        .slide {
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
            display: flex !important;
            align-items: flex-end;
            justify-content: center;
            font-size: 30px;
            min-height: 300px;
            transition: 1s all;
            transform: scale(.7);
        }        

        .slick-now .slide {
            transform: scale(1);
        }

        .slick-prev, .slick-next {
            background: none;
            color: transparent;
            height: 100%;
            margin: 0;
            max-width: 50%;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top: 50% !important;
            transform: translateY(-50%);
            width: 100%;
            z-index: 1;

            &:before {
                content: none;
            }

            &:hover {
                cursor: none;
            }
        }

        .cursor {
            background: #DFE9F0;
            border-radius: 50%;
            height: 0;
            position: fixed;
            width: 0;
            z-index: 999;

            &:after {
                color: #171C26;
                font-family: 'Canela-Thin';
                font-size: 13px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &.cursor-prev, &.cursor-next {
                height: 68px;
                width: 68px;
            }

            &.cursor-prev {

                &:after {
                    content: 'Previo';
                }
            }

            &.cursor-next {

                &:after {
                    content: 'Siguiente';
                }
            }
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }
}

@media (min-width: 1100px) {

    .block.gallery-home {

        .content {
            max-width: 79.1%;
        }
    }
}

@media (max-height: 800px) {

    .block.gallery-home {

        .content {
            max-width: 60%;
        }
    }
}

@media (min-width: 1440px) {

    .block.gallery-home {

        .content {
            max-width: 1140px;
        }
    }
}

@keyframes puff-in-center {
    0% {
      transform: scale(1.3);
      filter: blur(4px);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      filter: blur(0px);
      opacity: 1;
    }
}
