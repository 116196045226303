.block.how-we-do-it {
    padding-bottom: 50px;
    position: relative;

    &:before, &:after {
        content: '';
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &:before {
        background: url('../../imgs/shape01.svg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 255px;
        bottom: 788px;
    }

    &:after {
        background: #92A1B1;
        bottom: 0;
        height: 826px;
    }

    .holder {
        position: relative;
        z-index: 2;
    }

    .item {
        padding: 0 30px;
    }

    .item-one {
        background: #334251;
        color: #EDE9E2;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        padding: 120px 30px 268px;

        .text-wrapper {
            margin: 0 auto;
            max-width: 300px;
            text-align: center;
            width: 100%;
        }

        .image-wrapper {
            padding-bottom: 30px;
        }

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .index {
        font-family: 'Whyte-Light';
        font-size: 10px;
        line-height: 16px;
        position: absolute;
    }

    .image-wrapper {
        position: relative;
        width: 100%;
    }

    .item-two, .item-three, .item-four {

        .title {
            color: #202934;
            font-family: 'Canela-Thin';
            font-size: 28px;
        }

        .text {
            color: #707070;
            font-family: 'Whyte-Light';
            font-size: 13px;
            line-height: 19px;
            max-width: 280px;
            width: 100%;

            strong {
                font-family: 'Whyte-Regular';
                font-weight: normal;
            }
        }
    }

    .item-two, .item-three, .item-four, .item-five {
        margin: 0 auto;
        max-width: 550px;
        width: 100%;
    }

    .item-two {
        margin-top: -108px;

        .image-wrapper {
            max-width: 314px;
            padding-bottom: 5px;
            padding-right: 34px;
        }

        .index {
            bottom: 0;
            color: #171C26;
            right: 0;
        }

        .title {
            padding: 25px 0 20px;
        }
    }

    .item-three {
        margin-top: 56px;

        .image-wrapper {
            margin-left: auto;
            max-width: 295px;
            padding-left: 35px;
            padding-top: 4px;
        }

        .index {
            color: #171C26;
            left: 0;
            top: 0;
        }

        .text-wrapper {
            margin-left: auto;
            max-width: 260px;
            width: 100%;
        }

        .title {
            padding: 30px 0 20px;
        }
    }

    .item-four {
        margin-top: 60px;

        .image-wrapper {
            max-width: 314px;
            padding-bottom: 5px;
            padding-right: 34px;
        }

        .index {
            bottom: 0;
            color: #171C26;
            right: 0;
        }

        .title {
            padding: 25px 0 20px;
        }
    }

    .item-five {
        margin-top: 183px;

        .image-wrapper {
            margin-left: auto;
            max-width: 260px;
            padding-bottom: 35px;
        }

        .index {
            bottom: 0;
            color: #FBF8F8;
            right: 0;
        }

        .title {
            color: #FBF8F8;
            font-family: 'Canela-Thin';
            font-size: 28px;
        }

        .text {
            color: #FBF8F8;
            font-family: 'Whyte-Light';
            font-size: 13px;
            line-height: 19px;
            padding: 20px 0 30px;
        }
    }

    .item-six {
        margin-top: 64px;

        .scroll-element {
            display: none;
        }

        .content {
            padding: 118px 17px 105px;
            position: relative;
        }

        .background {
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .text {
            color: #FAF7F2;
            font-family: 'Canela-Thin';
            font-size: 22px;
            line-height: 28px;
            position: relative;
            text-align: center;
            z-index: 1;
        }
    }
}

@media (min-width: 640px) {

    .block.how-we-do-it {

        &:after {
            height: 788px;
        }
    }
}

@media (min-width: 768px) {

    .block.how-we-do-it {
        min-height: 100vh;
        padding-bottom: unset;
        position: relative;
        z-index: 999;

        &:before, &:after {
            content: none;
        }
    
        .screen-full {
            height: 100vh;
            width: fit-content;
        }
    
        .wrapper-sections {
            display: flex;
            left: 0;
            max-width: unset;
            position: relative;
            top: 0;
            width: fit-content;

            &:before {
                background: url('../../imgs/shape02.svg');
                background-position: center left;
                background-repeat: no-repeat;
                background-size: cover;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                right: 1906px;
                width: 583px;
            }

            &:after {
                background: #92A1B1;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: 2083px;
                z-index: -1;
            }
        }

        .flex-content {
            display: flex;
            justify-content: space-between;
        }

        .item {
            height: 100vh;
            padding: unset;
        }

        .item-one {
            font-size: 35px;
            line-height: 42px;
            min-width: 100vw;
            width: 100vw;

            .text {
                text-align: left;
            }
        }

        .item-one, .item-two, .item-three, .item-four, .item-five {

            .content {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .item-two, .item-three, .item-four, .item-five, .item-six {
            margin-top: unset;
        }

        .index {
            font-size: 12px;
            line-height: 20px;
        }

        .item-two, .item-three, .item-four {
    
            .title {
                font-size: 40px;
            }
    
            .text {
                font-size: 18px;
                line-height: 25px;
                min-width: 417px;
                width: 100%;
            }
        }

        .item-two, .item-three, .item-four, .item-five {
            max-width: unset;
        }

        .item-one {
            padding: 0 15px;
            position: relative;

            &:after {
                background: url('../../imgs/arrow02.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                height: 21px;
                position: absolute;
                right: 70px;
                top: 50%;
                transform: translateY(-50%);
                width: 25px;
            }

            .flex-content {
                align-items: center;
            }

            .image-wrapper {
                max-width: 50%;
                padding-bottom: unset;
            }

            .text-wrapper {
                max-width: 41%;
                padding-right: 121px;
            }
        }

        .item-two {
            position: relative;

            &:before {
                background: #334251;
                content: '';
                display: block;
                height: 100%;
                left: -1px;
                position: absolute;
                top: 0;
                width: 380px;
            }

            .image-wrapper {
                max-width: unset;
                min-width: 591px;
                padding-bottom: 6px;
                padding-right: 67px;
                width: 591px;
            }

            .title {
                padding: 0 0 30px;
            }
        }

        .item-three {

            .image-wrapper {
                min-width: 691px;
                padding-left: 167px;
                width: 691px;
            }

            .index {
                left: 120px;
            }

            .text-wrapper {
                max-width: unset;
                padding-left: 167px;
            }

            .title {
                padding: 50px 0 30px;
            }
        }

        .item-four {

            .image-wrapper {
                min-width: 644px;
                padding-bottom: 6px;
                padding-left: 120px;
                padding-right: 0;
            }

            .index {
                left: 73px;
                right: unset;
            }

            .text-wrapper {
                padding-left: 54px;
            }

            .title {
                padding: 0 0 30px;
            }
        }

        .item-five {
            padding-left: 350px;

            .image-wrapper {
                min-width: 591px;
                padding-bottom: 6px;
                padding-right: 65px;
            }

            .index {
                right: 17px;
            }

            .text-wrapper {
                max-width: 412px;
                width: 100%;
            }

            .title {
                font-size: 40px;
            }

            .text {
                font-size: 18px;
                line-height: 25px;
                padding: 30px 0 74px;
            }
        }

        .item-six {
            min-width: 100vw;
            padding: 123px 70px;
            position: relative;
            width: 100vw;

            .scroll-element {
                background: url('../../imgs/arrow01.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                bottom: 40px;
                content: '';
                display: block;
                height: 22px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 26px;
                z-index: 111;
            }

            .content {
                height: 100%;
                padding: 0;
            }

            .text-wrapper {
                height: 100%;
            } 

            .text {
                font-size: 40px;
                margin: 0 auto;
                max-width: 818px;
                padding: 0 15px;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@media (min-width: 1000px) {

    .block.how-we-do-it {

        .item-one {
            padding: 0 70px;
        }
    }
}

@media (max-height: 950px) and (min-width: 768px) {

    .block.how-we-do-it {

        .item-one {
            font-size: 30px;

            .image-wrapper {
                max-width: 35%;
            }
        }

        .item-two {

            &:before {
                width: 280px;
            }

            .image-wrapper {
                min-width: 390px;
                width: 390px;
            }
        }

        .item-three {

            .image-wrapper {
                min-width: 550px;
                padding-left: 160px;
                width: 550px;
            }
        }

        .item-four {

            .image-wrapper {
                min-width: 500px;
            }
        }

        .item-five {

            .image-wrapper {
                min-width: 450px;
            }
        }
    }
}

@media (max-height: 650px) and (min-width: 768px) {

    .block.how-we-do-it {

        .item-one {

            .image-wrapper {
                max-width: 30%;
            }
        }

        .item-two {

            .image-wrapper {
                min-width: 325px;
                width: 325px;
            }
        }

        .item-three {

            .image-wrapper {
                min-width: 400px;
                width: 400px;
            }

            .title {
                padding: 20px 0;
            }
        }

        .item-four {

            .image-wrapper {
                min-width: 420px;
            }
        }

        .item-five {

            .image-wrapper {
                min-width: 350px;
            }

            .text {
                padding: 20px 0;
            }
        }
    }
}