.block.purpose {
    padding: 120px 0 60px;
    position: relative;

    .image-wrapper {
        margin-top: 51px;

        &.hidden-xs {
            display: none;
        }
    }

    .text-wrapper {
        margin: 0 auto;
        max-width: 414px;
        padding: 0 30px;
    }

    .text-one {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .text-two {
        color: #707070;
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 17px;
        padding-top: 30px;
    }

    p {
        margin-bottom: 17px;

        &:last-child {
            margin-bottom: unset;
        }
    }
}

@media (min-width: 768px) {

    .block.purpose {
        padding: 138px 0 102px;

        .holder {
            margin: 0 auto;
            max-width: 1440px;
            width: 100%;
        }

        .content {
            align-items: center;
            display: flex;
        }

        .image-wrapper {
            display: block;
            margin-top: unset;
            max-width: 47.4%;
            padding: 0 70px;
            position: relative;
            width: 100%;

            &.hidden-xs {
                display: block;
            }

            &.visible-xs {
                display: none;
            }
        }

        .image {
            position: relative;
        }

        .background {
            height: 100%;
            left: 0;
            position: absolute !important;
            top: 0;
            width: 100%;
        }

        .text-wrapper {
            margin: unset;
            max-width: 52.6%;
            padding: 0 10% 0 5%;
            width: 100%;
        }

        .text-one {
            font-size: 30px;
            line-height: 36px;

            strong {
                color: #92A1B1;
                font-weight: normal;
            }
        }

        .text-two {
            font-size: 16px;
            line-height: 22px;
            max-width: 418px;
            padding-top: 48px;
            width: 100%;
        }

        p {
            margin-bottom: 25px;
        }
    }
}