.block.trajectory {
    padding: 60px 0;

    .header {
        background: #F4F0EA;
        padding: 100px 30px;
    }

    .title {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        margin: 0 auto;
        max-width: 414px;
        text-align: center;
        width: 100%;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .content {
        color: #707070;
        font-family: 'Canela-Thin';
        margin: 0 auto;
        margin-top: 50px;
        max-width: 414px;
        padding: 0 30px;
        text-align: center;
    }

    .phrase-wrapper {
        padding: 0 37px;
    }

    .phrase {
        font-size: 18px;
        line-height: 22px;
        padding-top: 30px;

        &.mobile {
            display: block;
        }

        &.desktop {
            display: none;
        }
    }

    .text-wrapper {
        font-size: 24px;
        line-height: 29px;
        padding-top: 60px;
    }
}

@media (min-width: 768px) {

    .block.trajectory {
        padding: 0 0 70px;

        .header {
            padding: 170px 22px 168px;
        }

        .title {
            font-size: 30px;
            line-height: 36px;
            max-width: 810px;

            strong {
                color: #92A1B1;
                font-weight: normal;
            }
        }

        .content {
            margin-top: 132px;
            max-width: 1031px;
            padding: 0 22px;
            text-align: left;
        }

        .flex-content {
            display: flex;
            justify-content: space-between;
        }

        .phrase-wrapper {
            max-width: 43.8%;
            padding: 0;
            position: relative;
            width: 100%;
        }

        .phrase {
            color: #92A1B1;
            font-family: 'Canela-Thin';
            font-size: 30px;
            letter-spacing: 0;
            line-height: 36px;
            padding-top: unset;
        }

        .text-wrapper {
            font-size: 30px;
            line-height: 36px;
            max-width: 47.3%;
            padding: unset;
            width: 100%;
        }
    }
}

body.dark-theme{
    .block.trajectory{
        .header {
            background: transparent;
        }
    }
}