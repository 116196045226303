.block.pillars {
    padding: 60px 15px;

    .holder {
        margin: 0 auto;
        max-width: 414px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .header {
        margin: 0 auto;
        max-width: 298px;
        width: 100%;
    }

    .title {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .content {
        padding-top: 20px;
    }

    .item {
        color: #707070;
        margin: 0 auto;
        max-width: 354px;
        padding: 60px 0 0;
        text-align: center;
        width: 100%;
    }

    .image {
        overflow: hidden;
        position: relative;
    }

    .background {
        height: 100%;
        left: 0;
        position: absolute !important;
        top: 0;
        width: 100%;
    }

    img {
        opacity: 0 !important;
    }

    .item-title {
        font-family: 'Canela-Thin';
        font-size: 26px;
        line-height: 26px;
        padding-top: 30px;
    }

    .item-desc {
        font-family: 'Whyte-Light';
        font-size: 14px;
        line-height: 17px;
        margin: 0 auto;
        max-width: 217px;
        padding-top: 20px;
        width: 100%;
    }
}

@media (min-width: 768px) {

    .block.pillars {
        padding: 70px 15px;

        .holder {
            max-width: 1108px;
        }

        .header {
            max-width: 476px;
        }

        .title {
            font-size: 35px;
            line-height: 42px;
        }

        .flex-content {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
        }

        .item {
            margin: unset;
            max-width: 28%;
        }

        .item-title {
            font-size: 30px;
            line-height: 28px;
            padding-top: 44px;
        }

        .item-desc {
            font-size: 16px;
            line-height: 21px;
            padding-top: 32px;
        }
    }
}