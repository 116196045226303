@font-face {
    font-family: 'Canela-Regular';
    src: url('../fonts/Canela-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Canela-Thin';
    src: url('../fonts/Canela-Thin.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Whyte-Light';
    src: url('../fonts/Whyte-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Whyte-Regular';
    src: url('../fonts/Whyte-Regular.ttf') format('truetype');
    font-display: swap;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    background: #FAF7F2;
    box-sizing: border-box;
    letter-spacing: 0;
    margin: 0;

    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
    transition: background 750ms ease-in-out;

    section {
        transition: background 750ms ease-in-out;
        
        div, a, span, strong, p {
            transition: color 750ms ease-in-out;
        }
    }

    &.no-scroll {
        overflow: hidden;
    }

    img {
        display: block;
        max-width: 100%;
        width: 100%;
    }

    p {
        margin: 0;
    }

    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .general-shape {
        opacity: 1;
    }

    a.btn {
        background: transparent;
        color: #171C26;
        transition: all 550ms ease-in-out;

        &:hover {
            background: #DFE9F0 !important;
        }
    }

    .block.how-we-do-it {
        background: #FAF7F2;
    }

    &.dark-theme {
        background: #202934;

        section, .block.how-we-do-it {
            background: #202934 !important;
            
            div, a, span, strong, p {
                color: #FAF7F2 !important;
            }
        }

        .general-shape {
            opacity: 0;
        }

        a.btn {
            background: transparent;
            color: #DFE9F0;
            transition: all 550ms ease-in-out;
            border: 1px solid #DFE9F0;
    
            &:hover {
                background: #334251 !important;
            }
        }
    }
}

.general-shape {
    background: url('../imgs/general-shape.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 865px;
    height: 1344px;
    position: absolute;
    transition: all 750ms ease-in-out;
    width: 100%;
}

.outer-wrapper {
    overflow: hidden;
    position: relative;
}

body.home {
    overflow: hidden;

    .block.cover-home {
        transform: translateY(100vh);
        transition: all 1500ms ease-in-out;
    }

    .loader {
        transition: all 1500ms ease-in-out;
    }

    &.hide-loader {

        .block.cover-home {
            transform: translateY(0);
        }

        .loader {
            transform: translateY(-100%);
        }
    }

    &.ready {
        overflow: initial;

        .block.cover-home {
            transition: all 550ms ease-in-out;
        }
    }
}

.text-loading {
    opacity: 0;
}

.hidden {
    display: none !important;
}

.text-inview, .text-waiting {
    opacity: 0;
    transition: all 750ms ease-in-out;
    transform: translateY(7%);

    &.viewed {
        opacity: 1;
        transform: translateY(0);
    }
}

.image-inview, .image-waiting {
    overflow: hidden;

    img {
        opacity: 0;
        transition: transform .6s cubic-bezier(.215,.61,.355,1), opacity .6s cubic-bezier(.215,.61,.355,1), border-radius 550ms ease-in-out, max-width 550ms ease-in-out !important;
        transform: skewY(3deg) scale(1.2) translateY(60px);
    }
    
    &.viewed {

        img {
            opacity: 1;
            transform: skewY(0deg) scale(1) translateY(0px);
        }
    }
}

.privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #202934;
    z-index: 9999999;
}

.loading-component {
    opacity: 0;
    transition: all 350ms ease-in-out;

    &.ready {
        opacity: 1;
    }
}

.block.not-found {
    height: 100vh;
    position: relative;
    width: 100vw;

    .text-wrapper {
        left: 50%;
        max-width: 100%;
        padding: 0 25px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    a {
        color: inherit;
        display: block;
        margin-top: 10px;
        text-decoration: none;
    }
}

@media (min-width: 768px) {

    .general-shape {
        bottom: 100vh;
    }
}

input:-webkit-autofill{
    background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #DFE9F0 inset;
}