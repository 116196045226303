.block.products-home {
    padding: 60px 15px 75px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 1300px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .title {
        color: #171C26;
        font-size: 28px;
        font-family: 'Canela-Thin';
        line-height: 28px;
        margin: 0 auto;
        max-width: 264px;
        text-align: center;
        width: 100%;
    }

    .content {
        margin-top: 30px;
    }

    .text-holder {
        padding-bottom: 30px;

        .item {
            margin-top: 30px;

            .item-name {
                color: #92A1B1;
                transition: all 550ms ease-in-out;
            }

            .text-wrapper, .image-holder {
                opacity: 0;
                transition: all 550ms ease-in-out;
            }

            &.active {

                .item-name {
                    color: #202934;
                }

                .text-wrapper, .image-holder {
                    opacity: 1;
                    color: #888888;
                }
            }
        }
    }

    .text-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        max-width: 277px;
        width: 100%;
    }

    .item-name {
        font-family: 'Canela-Thin';
        font-size: 22px;
    }

    .flex-content {
        color: #92A1B1;
        font-family: 'Whyte-Light';
        font-size: 12px;
    }

    .image-holder {
        display: block;
        margin: 0 auto;
        margin-top: 20px;
        max-width: 480px;
        width: 100%;
    }

    .text-two {
        margin-left: 38px;
    }

    .extra-text {
        margin-top: 82px;
    }

    .text {
        color: #202934;
        font-family: 'Canela-Thin';
        font-size: 22px;
        text-align: center;
    }

    a {
        background: transparent;
        border: 1px solid #171C26;
        border-radius: 50px;
        color: #171C26;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 10px;
        margin: 0 auto;
        margin-top: 30px;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;
    }

    .btn-effect {

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }
    }

    .slick-dots {
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
    }

    .carrousel {
        display: none;
    }
}

@media (min-width: 768px) {

    .block.products-home {
        left: 0;
        padding: 115px 15px 187px;
        position: relative;
        top: 0;
        width: 100%;

        .title {
            font-size: 30px;
            line-height: 58px;
            max-width: 100%;
        }

        .content {
            margin-top: 80px;
        }

        .image-wrapper {
            max-width: 100%;
            width: 100%;
        }
    
        .text-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
        }

        .item-name {
            /*cursor: pointer;*/
            font-size: 28px;
            line-height: 34px;
        }

        .flex-content {
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            line-height: 17px;
        }

        .extra-text {
            margin-top: 99px;
        }

        .text {
            font-size: 30px;
            line-height: 36px;
            margin: 0 auto;
            max-width: 634px;
            width: 100%;
        }

        .text-holder {
            max-width: 27%;
            width: 100%;

            .item {
                /*cursor: pointer;*/
                margin-top: 60px;
                max-width: 70%;
                width: 100%;
            }
        }

        .image-holder {
            display: none;
        }

        .text-one {
            max-width: 50%;
            width: 100%;
        }

        .text-two {
            max-width: 50%;
            width: 100%;
        }

        .carrousel {
            display: block;
            max-width: 67.4%;
            width: 100%;
        }

        a {
            font-size: 12px;
            overflow: hidden;
            position: relative;

            .btn-effect {

                &:nth-child(1) {
                    transform: translateY(0);
                }

                &:nth-child(2) {
                    transform: translate(-50%, 170%);
                }
            }

            &:hover {

                .btn-effect {

                    &:nth-child(1) {
                        transform: translateY(-170%);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .text-two {
            margin-left: 10px;
        }

        .btn-effect {
            transition: cubic-bezier(0.23, 1, 0.32, 1) 600ms transform;;
    
            &:nth-child(1) {
                display: block;
            }
    
            &:nth-child(2) {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }
    }
}