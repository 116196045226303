.block.cover-home {
    overflow: hidden;
    position: relative;
    transition: all 550ms ease-in-out;
    width: 100%;

    .background {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 100vh;
        left: 0;
        position: absolute !important;
        top: 0;
        width: 100%;

        &:after {
            background: #000000;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.46;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    video {
        bottom: 0;
        left: 50%;
        min-height: 100%;
        min-width: 100%; 
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 0;
    }

    .holder {
        position: relative;
    }

    .content {
        height: 100vh;
    }

    .text-wrapper {
        left: 50%;
        max-width: 384px;
        padding: 0 15px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .text {
        color: #FBF8F8;
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 30px;
        text-align: center;
    }
}

@media (min-width: 768px) {

    .block.cover-home {

        .text-wrapper {
            max-width: 860px;
        }

        .text {
            font-size: 40px;
            line-height: 46px;
        }
    }
}