.block.second-section-foundation {
    padding: 120px 0 100px;
    position: relative;

    .text-wrapper {
        padding: 0 30px;
    }

    .text {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 28px;
        line-height: 34px;
        text-align: center;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    .images-wrapper-one, .images-wrapper-two {
        margin: 0 auto;
        max-width: 85.5%;
        padding-top: 120px;
        position: relative;
    }

    .image-one {
        max-width: 66.2%;
        padding-bottom: 322px;
        width: 100%;
    }

    .image-two {
        bottom: 0;
        max-width: 80.3%;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .image-three {
        max-width: 100%;
        padding-top: 120px;
        width: 100%;
    }

    .image-four {
        padding-bottom: 30px;
    }

    .see-more {
        color: #707070;
        display: block;
        font-family: 'Canela-Thin';
        font-size: 28px;
        margin: 0 auto;
        padding-bottom: 11px;
        padding-top: 100px;
        position: relative;
        text-decoration: none;
        width: fit-content;

        &:after {
            background: #979797;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 100%;
        }
    }
}

@media (min-width: 768px) {

    .block.second-section-foundation {
        padding: 194px 15px 150px;

        .holder {
            margin: 0 auto;
            max-width: 1330px;
            width: 100%;
        }

        .container-fluid {
            padding: 0 15px;
        }

        .text-wrapper {
            margin: 0 auto;
            max-width: 823px;
            padding: 0;
            width: 100%;
        }

        .text {
            font-size: 30px;
            line-height: 36px;
        }

        .images-wrapper-one {
            max-width: 82.9%;
            padding-top: 150px;
        }

        .image-one {
            max-width: 48.6%;
            padding-bottom: 425px;
        }

        .image-two {
            max-width: 58.9%;
        }

        .image-three {
            margin: 0 auto;
            max-width: 1078px;
            padding-top: 150px;
        }

        .images-wrapper-two {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            max-width: 100%;
        }

        .image-four, .image-five {
            max-width: 48.8%;
            padding-bottom: unset;
            width: 100%;
        }

        .see-more {
            font-size: 30px;
            padding-bottom: 14px;
            padding-top: 145px;

            &:after {
                max-width: 86px;
            }
        }
    }
}