.block.experiences-home {
    padding: 120px 15px 60px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 500px;
        width: 100%;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .text-wrapper {
        margin: 0 auto;
        max-width: 856px;
        width: 100%;
    }

    .text {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        strong {
            color: #92A1B1;
            font-weight: normal;
        }
    }

    a.btn {
        background: transparent;
        border: 1px solid #707070;
        border-radius: 50px;
        color: #334251;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 10px;
        line-height: 12px;
        margin: 0 auto;
        margin-top: 30px;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        width: fit-content;
    }

    .btn-effect {

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }
    }

    .images-wrapper {
        padding-top: 20px;
    }

    .item {
        display: block;
        padding-top: 60px;
        max-width: 100%;
        text-decoration: none;
        width: 100%;

        img {
            border-radius: 262px 262px 0 0;
            overflow: hidden;
        }
    }

    div.item {
        position: relative;

        .image {
            
            &:after {
                content: 'Coming soon';
                color: #FBF8F8;
                font-family: 'Whyte-Light';
                font-size: 20px;
                left: 50%;
                letter-spacing: 0;
                line-height: 48px;
                position: absolute;
                text-transform: uppercase;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .title {
        color: #707070;
        font-family: 'Canela-Thin';
        font-size: 28px;
        padding-bottom: 20px;
        text-align: center;
    }

    .image {
        position: relative;
    }

    .coming-soon {
        color: #FFFFFF;
        font-family: 'Whyte-Light';
        font-size: 17px;
        left: 50%;
        position: absolute;
        text-transform: uppercase;
        top: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
    }
}

@media (min-width: 768px) {

    .block.experiences-home {
        padding: 179px 0 115px;

        .holder {
            max-width: 1108px;
        }

        .text {
            font-size: 30px;
            line-height: 36px;
        }

        a.btn {
            font-size: 12px;
            line-height: 20px;
            margin-top: 60px;
            overflow: hidden;
            padding: 7px 18px;
            position: relative;

            .btn-effect {

                &:nth-child(1) {
                    transform: translateY(0);
                }

                &:nth-child(2) {
                    transform: translate(-50%, 170%);
                }
            }

            &:hover {

                .btn-effect {

                    &:nth-child(1) {
                        transform: translateY(-170%);
                    }

                    &:nth-child(2) {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .btn-effect {
            transition: cubic-bezier(0.23, 1, 0.32, 1) 600ms transform;;
    
            &:nth-child(1) {
                display: block;
            }
    
            &:nth-child(2) {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }

        .images-wrapper {
            display: flex;
            justify-content: space-between;
            padding-top: 80px;
        }

        .item {
            margin-top: unset;
            max-width: 48.6%;

            img {
                border-radius: 0;
                transition: all 250ms ease-in-out;
            }
    
            &:hover {
    
                img {
                    border-radius: 262px 262px 0 0;
                }
            }
        }

        .title {
            font-size: 35px;
            padding-bottom: 50px;
        }

        .coming-soon {
            font-size: 29px;
        }
    }
}