.status-message {
    background: transparent;
    border-radius: 2px;
    height: 100%;
    left: 50%;
    max-width: 102%;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 3;

    .sending, .success, .error {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        .icon {
            margin: 0 auto 15px;
            max-width: 52px;
            width: 100%;
        }
    }

    .error, .success .message, .sending {
        color: #171C26;
        font-family: 'Canela-Thin';
        font-size: 22px;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
    }
}