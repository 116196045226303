.contact-form {
    padding-top: 30px;
    position: relative;

    .wrapper-content-form {
        opacity: 1;

        &.partial-hide {
            opacity: 0;
        }
    }

    input[type=text], input[type=date], textarea {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        background: transparent;
        border: none;
        border-bottom: 1px solid #979797;
        color: #171C26;
        display: block;
        font-family: 'Whyte-Light';
        font-size: 12px;
        margin-top: 30px;
        max-width: 100%;
        outline: none;
        padding: 11px 15px 15px;
        position: relative;
        width: 100%;
    }

    input[type=date]:required:invalid {
        color: transparent;
        position: relative;

        &:before {
            background: #DFE9F0;
            box-sizing: border-box;
            color: #171C26;
            content: 'Fecha de tu evento';
            display: block;
            font-family: 'Whyte-Light';
            font-size: 12px;
            height: 100%;
            left: 0;
            opacity: .7;
            padding: 11px 15px 15px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        &:required:valid {
            color: #171C26;

            &:before {
                opacity: 0;
            }
        }
    }

    input[type=date]::-webkit-inner-spin-button,
    input[type=date]::-webkit-calendar-picker-indicator {
        box-sizing: border-box;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    textarea {
        height: 120px;
        resize: none;
    }

    input[type=submit] {
        background: transparent;
        border: none;
        color: #171C26;
        display: block;
        font-family: 'Canela-Thin';
        font-size: 22px;
        margin-left: auto;
        margin-top: 35px;
        outline: none;
        padding: 0;
        width: fit-content;
    }
}

@media (min-width: 768px) {

    .contact-form {
        padding-top: 105px;

        .inputs-container {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
        }

        input[type=text], input[type=date], textarea {
            font-size: 14px;
            line-height: 17px;
        }

        input[type=text], input[type=date] {
            margin-top: unset;
            max-width: 23%;
            padding: 9px 15px 14px;
        }

        textarea {
            height: 90px;
            margin-top: 80px;
            max-width: 83%;
        }

        input[type=submit] {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            margin-top: unset;
            width: 190px;
        }
    }
}

@media (max-height: 800px) {

    .contact-form {
        padding-top: 20px;

        textarea {
            height: 70px;
            margin-top: 20px;
        }
    }
}